export default{
  banner: {
   
  },
  cards:[
    {
      title: '收银业务',
      content: '维修业务、洗车、美容、装潢、轮胎、保险、顾客预约、东西寄存<br/>'+
      '通过搜索客户姓名、手机号或是扫描车牌，选定客户，选择施工项目以及每个项目对应的服务顾问和施工人员，便可自动生成订单，客户到收银台无需再等待手动纸质开单，就可直接付款买单。'
    },
    {
      title: '积分管理',
      content: '系统累计顾客的消费积分，并自动通过微信发送通知给顾客。'
    },
    {
      title: '库存业务',
      content: '包含采购、领料、报损、退货、调拨等商品管理并生成库存报表，查看库存商品情况，并可快速搜索库存产品品类。'
    },
    {
      title: '员工绩效',
      content: '18个奖励方案，满足不同门店的绩效设置需求<br/>' +
      '查看员工绩效明细、排名、个人工资情况以及每个员工的贡献毛利分析。客户一经结算，员工就可立即收到业绩提醒，系统还能将业绩奖励设置成微信红包秒到的方式发送给员工，该绩效发放方式可以大大刺激员工的积极性。'
    },
    {
      title: '财务报表',
      content: '包含毛利报表、应付款、应收款三大功能<br/>' +
      '毛利报表：不仅可查看月度、年度、历年的利润分析，还可以查看每个项目产生的利润以及利润占比。<br/>'+
      '应付款：查看供应商应付款项明细以及应付款占比情况，并具有快速搜索功能。<br/>' +
      '应收款：查看客户应收款项明细以及应收款占比情况，并具有快速搜索功能。'
    },
    {
      title: '活动策划',
      content: '包含会员、新老客户、车辆档次类别等营销方案<br/>'+
      '查看每个门店的活动报表、员工业绩、新客户获取、发送记录、分享记录、领取记录、带动营业额的数据，并可查看活动当日、月度、年度的数据汇总分析。老板或管理者可随时监督活动效果。'
    },
    {
      title: '管理方案',
      content: '包含大客户、潜在客户、重点服务法则等方面管理分析<br/>'+
      '通过输入或扫一扫客户的车牌，可查看该车主的基础资料、车辆资料（包括车辆信息、保养信息、配置信息）、会员卡情况。其中车辆资料中的保养信息是主机厂的数据，准确告知这辆车3万公里应该做什么，4万公里应该做什么，服务顾问可以将该数据提供给车主查看，更具说服力。若客户还不是会员，可以推荐客户办理会员卡并直接在系统中新增客户资料。还可通过该模块查看客户的消费记录、回访事项以及直接开单等操作。'
    },
    {
      title: '售后方案',
      content: '包含项目保养、保险到期、套餐到期等自动提醒车主跟商家老板或管理者权限可查看员工的客户回访总览及每个员工的回访情况，包括待回访、已逾期、已失效、已回访等信息，实时监督查看，提高员工积极性。<br/>' +
      '普通员工权限可查看个人的客户回访情况以及提醒事项，还可以直接在系统中点击客户回访，回访记录会自动记录在已回访列表，暂未回访或超期未回访客户则会自动统计在待回访或是逾期回访的列表中。'
    }
  ]
}