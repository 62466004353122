














import { Component, Vue } from 'vue-property-decorator';
import vFreeApply from '@/components/v-free-apply.vue';
import VBanner from '@/components/v-banner.vue';
import VCard from '@/components/v-card.vue'
import softData from '../../assets/data/soft'


@Component({ 
  components: { vFreeApply,VBanner, VCard  }
})
export default class extends Vue {
  private bannerData = softData.banner
  private cardsData = softData.cards
}
